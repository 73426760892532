import * as React from "react";

export default function Home() {
  return (
    <div className="float-right">
      <a href="/">
        <div className="label-name on-hover-40 text-white"></div>
      </a>
    </div>
  );
}
